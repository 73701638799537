<template>
  <div>
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Base",
  components: {
    Header,
    Footer
  },
  created() {
    this.$authAxios.get('/api/cf/token')
  }
};
</script>

<style>
@import "../font/icomoon/style.css";
@import "../css/reset.min.css";
@import "../css/common.css";
</style>