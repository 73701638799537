<template>
  <header class="c_head">
    <div class="c_head_row">
      <p class="c_head_catch">サイバー犯罪に立ち向かう新たな取り組み</p>
      <a class="c_head_logo" @click="$router.push({ name: 'Home' })">
        <img
          src="/img/cmn/img-cmnfhd-logo@2x.png"
          width="287"
          height="57"
          alt="日本サイバー犯罪対策センター"
        />
      </a>
      <div class="c_head_nav">
        <div class="c_head_nav_srch">
          <label>
            <input
              type="text"
              placeholder="キーワード検索"
              v-model="search"
              @keyup.prevent.enter="doSearch"
            />
          </label>
          <button type="submit">
            <span class="icon-search"></span>
          </button>
        </div>
        <a class="c_head_nav_mypage" @click="$router.push({ name: 'Mypage' })">
          <img src="/img/cmn/img-profile.jpg" width="35" height="35" alt />
        </a>
        <a
          class="c_head_nav_menu"
          href="/static/manual/研究・研修ポータル_簡単利用ガイド.pdf"
          target="_blank"
          >マニュアル</a
        >
        <a v-if="isAdmin" class="c_head_nav_menu" href="/manage">管理</a>
        <a class="c_head_nav_logout" @click="$auth.logout()">ログアウト</a>
      </div>
      <button
        id="c_head_btn"
        class="c_head_btn"
        type="button"
        v-on:click="toggleNav"
      >
        <span class="-top"></span>
        <span class="-mid"></span>
        <span class="-btm"></span>
      </button>
    </div>
    <nav id="c_spnav" class="c_spnav">
      <ul class="c_spnav_list">
        <li><a href="/basics">サイバー関連サービス等解説コンテンツ</a></li>
        <li class="hide-sp"><a href="/tool">みんなの支援ツール</a></li>
        <li class="hide-sp"><a href="/dojo">サイバー道場</a></li>
        <li><a href="/trend">サイバー最新トレンド</a></li>
        <li class="hide-sp">
          <a href="/face_up">サイバー空間の脅威に立ち向かえ！</a>
        </li>
        <li class="hide-sp"><a href="/ctf">CTF</a></li>
        <li class="hide-sp"><a href="/advanced_dojo">上級道場</a></li>
      </ul>
      <div class="c_spnav_mypage">
        <a class="c_btn" href="/mypage">マイページを見る</a>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  watch: {
    "$route.query": function() {
      if (this.$route.query.s) {
        this.search = this.$route.query.s;
      } else if (this.$route.query.tag) {
        this.search = `#${this.$route.query.tag}`;
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$auth.scopes.includes("admin:portal");
    },
  },
  methods: {
    doSearch() {
      if (this.search) {
        if (this.search.startsWith("#")) {
          this.$router.push({
            name: "Search",
            query: { tag: this.search.slice(1) },
          });
        } else {
          this.$router.push({ name: "Search", query: { s: this.search } });
        }
      }
    },
    goAdmin() {
      this.$router.push({ name: "Admin" });
    },
    toggleNav() {
      document.getElementById("c_head_btn").classList.toggle("-open");
      document.getElementById("c_spnav").classList.toggle("-open");
    },
  },
};
</script>
